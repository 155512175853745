import { v4 } from 'uuid';
import { isEmpty, remove, reverse } from 'lodash';
import DOMPurify from 'dompurify';

export const getYear = (date) => {
  if (!date) {
    return;
  }

  const year = date?.split('-')[0];
  const currentYear = new Date().getFullYear();

  // If the year has 0 in front of it [0330], then remove starting 0 [330] and return the year.
  if (year?.charAt(0) === '0') {
    return year?.substring(1) + ' AD';
  }

  // If the year is greater than current year, then it is BC.
  if (year > currentYear) {
    return year + ' BC';
  }

  // return year in AD by default
  return year + ' AD';
};

export const getEventDate = (e) => {
  if (!e) return;

  // get years from the date response
  const startDateYear = getYear(e.event_year);
  const endDateYear = getYear(e.event_end_date);

  // format response data into js date
  const startDate = new Date(e.event_year);
  const endDate = new Date(e.event_end_date);

  // get start date in full format i.e. 19 October 1998
  const startDateRange =
    startDate.getDate() +
    ' ' +
    startDate
      .toLocaleString('default', {
        month: 'long',
      })
      .toUpperCase() +
    ', ' +
    startDateYear;

  const endDateRange =
    endDate.getDate() +
    ' ' +
    endDate
      .toLocaleString('default', {
        month: 'long',
      })
      .toUpperCase() +
    ', ' +
    endDateYear;

  if (e.date_type === 'Date Range' && e.date_accuracy === 'Display specific date') {
    return `${startDateRange} — ${endDateRange}`;
  }

  if (e.date_type === 'Date Range' && e.date_accuracy === 'Display year only') {
    return `${startDateYear} — ${endDateYear}`;
  }

  if (e.date_type === 'Single Date' && e.date_accuracy === 'Display specific date') {
    return `${startDateRange}`;
  }

  if (e.date_type === 'Single Date' && e.date_accuracy === 'Display year only') {
    return `${startDateYear}`;
  }

  return `${startDateYear} — ${endDateYear}`;
};

export const normalizePath = (path) => {
  const pathStr = path.split('/');

  // If the path ends with '/' get the second last item
  if (path?.endsWith(`/`)) {
    const strIndex = pathStr.length ? pathStr.length - 2 : '';

    if (strIndex) {
      path = `/${pathStr[strIndex]}/`;
    }
  }

  // If the path ends with '/' get the second last item.
  if (!path?.endsWith(`/`)) {
    const strIndex = pathStr.length ? pathStr.length - 1 : '';

    if (strIndex) {
      path = `/${pathStr[strIndex]}/`;
    }
  }

  return path;
};
/**
 * Get date in format of m-d-y
 *
 * @param {string} dateString Date string, example 2020-05-03T04:41:12
 *
 * @return {string}
 */
export const getFormattedDate = (dateString) => {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);

  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
};

/**
 * Extracts and returns float value from a string.
 *
 * @param {string} string String
 * @return {any}
 */
export const getFloatVal = (string) => {
  let floatValue = string.match(/[+-]?\d+(\.\d+)?/g)[0];
  return null !== floatValue ? parseFloat(parseFloat(floatValue).toFixed(2)) : '';
};

/**
 * Add first product.
 *
 * @param {Object} product Product
 * @return {{totalProductsCount: number, totalProductsPrice: any, products: Array}}
 */
export const addFirstProduct = (product) => {
  let productPrice = getFloatVal(product.price);

  let newCart = {
    products: [],
    totalProductsCount: 1,
    totalProductsPrice: productPrice,
  };

  const newProduct = createNewProduct(product, productPrice, 1);
  newCart.products.push(newProduct);

  localStorage.setItem('woo-next-cart', JSON.stringify(newCart));

  return newCart;
};

/**
 * Create a new product object.
 *
 * @param {Object} product Product
 * @param {Integer} productPrice Product Price
 * @param {Integer} qty Quantity
 * @return {{image: *, productId: *, totalPrice: number, price: *, qty: *, name: *}}
 */
export const createNewProduct = (product, productPrice, qty) => {
  return {
    productId: product.productId,
    image: product.image,
    name: product.name,
    price: productPrice,
    qty,
    totalPrice: parseFloat((productPrice * qty).toFixed(2)),
  };
};

/**
 * Updates the existing cart with new item.
 *
 * @param {Object} existingCart Existing Cart.
 * @param {Object} product Product.
 * @param {Integer} qtyToBeAdded Quantity.
 * @param {Integer} newQty New Qty to be updated.
 * @return {{totalProductsCount: *, totalProductsPrice: *, products: *}}
 */
export const updateCart = (existingCart, product, qtyToBeAdded, newQty = false) => {
  const updatedProducts = getUpdatedProducts(existingCart.products, product, qtyToBeAdded, newQty);

  const addPrice = (total, item) => {
    total.totalPrice += item.totalPrice;
    total.qty += item.qty;

    return total;
  };

  // Loop through the updated product array and add the totalPrice of each item to get the totalPrice
  let total = updatedProducts.reduce(addPrice, { totalPrice: 0, qty: 0 });

  const updatedCart = {
    products: updatedProducts,
    totalProductsCount: parseInt(total.qty),
    totalProductsPrice: parseFloat(total.totalPrice),
  };

  localStorage.setItem('woo-next-cart', JSON.stringify(updatedCart));

  return updatedCart;
};

/**
 * Get updated products array
 * Update the product if it exists else,
 * add the new product to existing cart,
 *
 * @param {Object} existingProductsInCart Existing product in cart
 * @param {Object} product Product
 * @param {Integer} qtyToBeAdded Quantity
 * @param {Integer} newQty New qty of the product (optional)
 * @return {*[]}
 */
export const getUpdatedProducts = (existingProductsInCart, product, qtyToBeAdded, newQty = false) => {
  // Check if the product already exits in the cart.
  const productExitsIndex = isProductInCart(existingProductsInCart, product.productId);

  // If product exits ( index of that product found in the array ), update the product quantity and totalPrice
  if (-1 < productExitsIndex) {
    let updatedProducts = existingProductsInCart;
    let updatedProduct = updatedProducts[productExitsIndex];

    // If have new qty of the product available, set that else add the qtyToBeAdded
    updatedProduct.qty = newQty ? parseInt(newQty) : parseInt(updatedProduct.qty + qtyToBeAdded);
    updatedProduct.totalPrice = parseFloat((updatedProduct.price * updatedProduct.qty).toFixed(2));

    return updatedProducts;
  } else {
    // If product not found push the new product to the existing product array.
    let productPrice = getFloatVal(product.price);
    const newProduct = createNewProduct(product, productPrice, qtyToBeAdded);
    existingProductsInCart.push(newProduct);

    return existingProductsInCart;
  }
};

/**
 * Returns index of the product if it exists.
 *
 * @param {Object} existingProductsInCart Existing Products.
 * @param {Integer} productId Product id.
 * @return {number | *} Index Returns -1 if product does not exist in the array, index number otherwise
 */
const isProductInCart = (existingProductsInCart, productId) => {
  const returnItemThatExits = (item, index) => {
    if (productId === item.productId) {
      return item;
    }
  };

  // This new array will only contain the product which is matched.
  const newArray = existingProductsInCart.filter(returnItemThatExits);

  return existingProductsInCart.indexOf(newArray[0]);
};

/**
 * Remove Item from the cart.
 *
 * @param {Integer} productId Product Id.
 * @return {any | string} Updated cart
 */
export const removeItemFromCart = (productId) => {
  if (typeof window === 'undefined') {
    return null;
  }

  let existingCart = localStorage.getItem('woo-next-cart');
  existingCart = JSON.parse(existingCart);

  // If there is only one item in the cart, delete the cart.
  if (1 === existingCart.products.length) {
    localStorage.removeItem('woo-next-cart');
    return null;
  }

  // Check if the product already exits in the cart.
  const productExitsIndex = isProductInCart(existingCart.products, productId);

  // If product to be removed exits
  if (-1 < productExitsIndex) {
    const productTobeRemoved = existingCart.products[productExitsIndex];
    const qtyToBeRemovedFromTotal = productTobeRemoved.qty;
    const priceToBeDeductedFromTotal = productTobeRemoved.totalPrice;

    // Remove that product from the array and update the total price and total quantity of the cart
    let updatedCart = existingCart;
    updatedCart.products.splice(productExitsIndex, 1);
    updatedCart.totalProductsCount = updatedCart.totalProductsCount - qtyToBeRemovedFromTotal;
    updatedCart.totalProductsPrice = updatedCart.totalProductsPrice - priceToBeDeductedFromTotal;

    localStorage.setItem('woo-next-cart', JSON.stringify(updatedCart));
    return updatedCart;
  } else {
    return existingCart;
  }
};

/**
 * Returns cart data in the required format.
 * @param {String} data Cart data
 */
export const getFormattedCart = (data) => {
  let formattedCart = null;

  if (undefined === data || !data.cart.contents.nodes.length) {
    return formattedCart;
  }

  const givenProducts = data.cart.contents.nodes;

  // Create an empty object.
  formattedCart = {};
  formattedCart.products = [];
  let totalProductsCount = 0;

  for (let i = 0; i < givenProducts.length; i++) {
    const givenProduct = givenProducts[i].product;
    const product = {};
    const total = getFloatVal(givenProducts[i].total);

    product.productId = givenProduct.productId;
    product.cartKey = givenProducts[i].key;
    product.name = givenProduct.name;
    product.qty = givenProducts[i].quantity;
    product.price = total / product.qty;
    product.totalPrice = givenProducts[i].total;
    product.variation = givenProducts[i].variation;

    // Ensure we can add products without images to the cart
    !isEmpty(givenProduct.image)
      ? (product.image = {
          sourceUrl: givenProduct.image.sourceUrl,
          srcSet: givenProduct.image.srcSet,
          title: givenProduct.image.title,
        })
      : (product.image = {
          sourceUrl: 'https://via.placeholder.com/434',
          srcSet: 'https://via.placeholder.com/434',
          title: givenProduct.name,
        });

    totalProductsCount += givenProducts[i].quantity;

    // Push each item into the products array.
    formattedCart.products.push(product);
  }

  formattedCart.totalProductsCount = totalProductsCount;
  formattedCart.totalProductsPrice = data.cart.total;
  formattedCart.subTotalPrice = data.cart.subtotal;
  formattedCart.shippingTotalPrice = data.cart.shippingTotal;

  return formattedCart;
};

// export const createCheckoutData = (order) => {
//   const checkoutData = {
//     clientMutationId: v4(),
//     billing: {
//       firstName: order.firstName,
//       lastName: order.lastName,
//       address1: order.address1,
//       address2: order.address2,
//       city: order.city,
//       country: order.country,
//       state: order.state,
//       postcode: order.postcode,
//       email: order.email,
//       phone: order.phone,
//       company: order.company,
//     },
//     shipping: {
//       firstName: order.firstName,
//       lastName: order.lastName,
//       address1: order.address1,
//       address2: order.address2,
//       city: order.city,
//       country: order.country,
//       state: order.state,
//       postcode: order.postcode,
//       email: order.email,
//       phone: order.phone,
//       company: order.company,
//     },
//     shipToDifferentAddress: false,
//     paymentMethod: order.paymentMethod,
//     isPaid: false,
//     transactionId: "hjkhjkhsdsdiui",
//     customerNote: order.customerNote,
//   };

//   if (order.createAccount) {
//     checkoutData.account = {
//       username: order.username,
//       password: order.password,
//     };
//   }

//   return checkoutData;
// };

export const createCheckoutData = (order, shipToDiffAdd = false, isPaid = false, transactionId) => {
  var checkoutData = {
    clientMutationId: v4(),
    billing: order.billing,
    shipToDifferentAddress: shipToDiffAdd,
    paymentMethod: order.paymentMethod,
    isPaid,
    transactionId,
    customerNote: order.notes,
  };
  if (shipToDiffAdd) {
    checkoutData.shipping = order.shipping;
  }
  if (order.createAccount) {
    checkoutData.account = {
      username: order.username,
      password: order.password,
    };
  }

  return checkoutData;
};

/**
 * Get the updated items in the below format required for mutation input.
 *
 * [
 * { "key": "33e75ff09dd601bbe6dd51039152189", "quantity": 1 },
 * { "key": "02e74f10e0327ad868d38f2b4fdd6f0", "quantity": 1 },
 * ]
 *
 * Creates an array in above format with the newQty (updated Qty ).
 *
 */
export const getUpdatedItems = (products, newQty, cartKey) => {
  // Create an empty array.
  const updatedItems = [];

  // Loop through the product array.
  products.map((cartItem) => {
    // If you find the cart key of the product user is trying to update, push the key and new qty.
    if (cartItem.cartKey === cartKey) {
      updatedItems.push({
        key: cartItem.cartKey,
        quantity: parseInt(newQty),
      });

      // Otherwise just push the existing qty without updating.
    } else {
      updatedItems.push({
        key: cartItem.cartKey,
        quantity: cartItem.qty,
      });
    }
    return null;
  });

  // Return the updatedItems array with new Qtys.
  return updatedItems;
};

export const isUserLoggedIn = () => {
  let authData = null;

  if (typeof window !== 'undefined') {
    authData = JSON.parse(localStorage.getItem('auth'));
  }
  return authData;
};

export const logOut = () => {
  localStorage.removeItem('auth');
};

export const setAuth = (authData) => {
  localStorage.setItem('auth', JSON.stringify(authData));
};

/**
 * Check if user is logged in.
 *
 * @return {object} Auth Object containing token and user data, false on failure.
 */
export const isUserValidated = () => {
  let userLoggedInData = '';

  if (typeof window !== 'undefined') {
    let authTokenData = localStorage.getItem('auth');

    if (!isEmpty(authTokenData)) {
      authTokenData = JSON.parse(authTokenData);

      if (!isEmpty(authTokenData.token)) {
        userLoggedInData = authTokenData.token;
      }
    }
  }

  return userLoggedInData;
};

/**
 * Function to get opengraph image.
 *
 * @param {Object} seo Seo data.
 *
 * @return {void}
 */
export const getOgImage = (seo) => {
  if (isEmpty(seo) || isEmpty(seo.opengraphImage) || isEmpty(seo.opengraphImage.sourceUrl)) {
    return getDefaultOgImage(seo);
  }

  return seo.opengraphImage.sourceUrl;
};

/**
 * Function to get opengraph default image.
 *
 * @param {Object} seo Seo data.
 *
 * @return {void}
 */
export const getDefaultOgImage = (seo) => {
  if (
    isEmpty(seo) ||
    isEmpty(seo.social) ||
    isEmpty(seo.social.facebook) ||
    isEmpty(seo.social.facebook.defaultImage) ||
    isEmpty(seo.social.facebook.defaultImage.sourceUrl)
  ) {
    return '';
  }

  return seo.social.facebook.defaultImage.sourceUrl;
};

/**
 * Add to wish list
 * @param {Object} productData Product data.
 */
export const addToWishList = (productData) => {
  let updatedWishList;

  // Get the existing value of wishlist from localStorage.
  const existingWishList = JSON.parse(localStorage.getItem('woo_wishlist'));

  /**
   * If its the first item
   */

  // Set it in localStorage and return.
  if (isEmpty(existingWishList)) {
    updatedWishList = addWishListToLocalStorage({
      productIds: [productData.node.productId],
      products: [productData],
    });
    return updatedWishList;
  }

  /**
   * If its not the first item
   */

  // First set the updated wishlist to existing one.
  updatedWishList = existingWishList;

  // Then push the new items to existing array.
  if (!existingWishList.productIds.includes(productData.node.productId)) {
    updatedWishList.productIds.push(productData.node.productId);
    updatedWishList.products.push(productData);
  }

  // Update the localStorage with updated items.
  addWishListToLocalStorage(updatedWishList);
};

/**
 * Remove item from the list.
 *
 * @param productId
 * @param getWishList
 * @param setWishList
 */
export const removeProductFromWishList = (productId, getWishList, setWishList) => {
  const existingWishlist = getWishListProducts();
  let updatedWishList;

  if (!isEmpty(existingWishlist)) {
    const updatedItems = {
      productIds: remove(existingWishlist.productIds, (id) => {
        return productId !== id;
      }),
      products: remove(existingWishlist.products, (product) => {
        return productId !== product.node.productId;
      }),
    };

    updatedWishList = addWishListToLocalStorage(updatedItems);

    if (0 === updatedItems.productIds.length) {
      setWishList(null);
    } else {
      getWishList();
    }

    return updatedWishList;
  }
};

/**
 * Add wishlist products to localStorage.
 *
 * @param wishList
 */
export const addWishListToLocalStorage = (wishList) => {
  return localStorage.setItem('woo_wishlist', JSON.stringify(wishList));
};

/**
 * Checks if the product with given id exists in the wishlist.
 *
 * @param productId
 * @returns {boolean}
 */
export const isProductInWishList = (productId) => {
  if (typeof window === 'undefined') {
    return null;
  }
  const existingWishList = JSON.parse(localStorage.getItem('woo_wishlist'));

  if (!isEmpty(existingWishList)) {
    return existingWishList.productIds.includes(productId);
  } else {
    return false;
  }
};

export const getWishListProducts = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  return JSON.parse(localStorage.getItem('woo_wishlist'));
};

/**
 * Sanitize markup or text when used inside dangerouslysetInnerHTML
 *
 * @param {string} content Plain or html string.
 *
 * @return {string} Sanitized string
 */
export const sanitize = (content) => {
  return typeof window !== 'undefined' ? DOMPurify.sanitize(content) : content;
};

export const mapWatchPageData = (data) => {
  const { season1, season2, season3, churches7, seasons, bts, episodes360, people, bible, place, adverts, trailers } =
    data;

  let seasonMapped = [];

  const season1Data = seasons.nodes.find((s) => s.title === `Season 1`);

  if (season1Data) {
    seasonMapped.push({
      title: 'Season 1',
      id: 'season1',
      episodes: season1.nodes,
      seasonTitle: season1Data.acfSeason.seasonTitle,
      link: '/episode_category/season1/',
    });
  }

  const season2Data = seasons.nodes.find((s) => s.title === `Season 2`);

  if (season2Data) {
    seasonMapped.push({
      title: 'Season 2',
      id: 'season2',
      episodes: season2.nodes,
      seasonTitle: season2Data.acfSeason.seasonTitle,
      link: '/episode_category/season2/',
    });
  }

  const season3Data = seasons.nodes.find((s) => s.title === `Season 3`);

  if (season3Data) {
    seasonMapped.push({
      title: 'Season 3',
      id: 'season3',
      episodes: season3.nodes,
      seasonTitle: season3Data.acfSeason.seasonTitle,
      link: '/episode_category/season3/',
    });
  }

  // const churches7Data = seasons.nodes.find(s => s.title === `Season 3`)

  if (churches7) {
    seasonMapped.push({
      title: 'The 7 Churches of Revelation',
      id: 'churches7',
      episodes: churches7.nodes,
      // seasonTitle: season3Data.acfSeason.seasonTitle,
      link: '/episode_category/7-churches/',
    });
  }

  // let timeframe = []
  let bibleMapped = [];

  if (bible && bible.timePeriods && bible.timePeriods.nodes && bible.timePeriods.nodes.length > 0) {
    let bibleEpisodes = [];
    for (let index = 0; index < bible.timePeriods.nodes.length; index++) {
      const timePeriod = bible.timePeriods.nodes[index];

      if (
        timePeriod &&
        timePeriod.acfTimePeriod &&
        timePeriod.acfTimePeriod.subPeriod &&
        timePeriod.acfTimePeriod.subPeriod.length > 0
      ) {
        for (let jndex = 0; jndex < timePeriod.acfTimePeriod.subPeriod.length; jndex++) {
          const subPeriod = timePeriod.acfTimePeriod.subPeriod[jndex];

          if (
            subPeriod &&
            subPeriod.acfSubPeriod &&
            subPeriod.acfSubPeriod.episodes &&
            subPeriod.acfSubPeriod.episodes.length > 0
          ) {
            bibleEpisodes = [...bibleEpisodes, ...subPeriod.acfSubPeriod.episodes];
          }
        }
      }
    }

    bibleMapped = [
      {
        title: 'Bible',
        id: 'bible',
        episodes: bibleEpisodes,
      },
    ];
  }

  let btsMapped = [];

  if (bts) {
    btsMapped = [
      {
        title: 'Behind the Scenes',
        id: 'bts',
        episodes: bts.nodes,
        link: '/episode_category/bts/',
      },
    ];
  }

  let eposides360Mapped = [];

  if (episodes360) {
    eposides360Mapped = [
      {
        title: '360',
        id: '360',
        episodes: [...episodes360.nodes],
        link: '/episode_category/360/',
      },
    ];
  }

  let advertsMapped = [];
  let trailersMapped = [];

  if (adverts) {
    advertsMapped = [
      {
        title: 'Adverts',
        id: 'adverts',
        episodes: [...adverts.nodes],
        link: '/episode_category/adverts/',
      },
    ];
  }

  if (trailers) {
    trailersMapped = [
      {
        title: 'Trailers',
        id: 'trailers',
        episodes: [...trailers.nodes],
        link: '/episode_category/trailers/',
      },
    ];
  }

  let peopleMapped = [];

  if (people && people.nodes && people.nodes.length > 0) {
    peopleMapped = [
      {
        title: 'People',
        id: 'people',
        episodes: people.nodes.map((person) => {
          return {
            uri: person.uri,
            date: person.date,
            acfEpisode: {
              episodeTitle: person.title,
              thumbnailImage: person.acfPerson.image,
            },
          };
        }),
        link: '/people/',
      },
    ];
  }

  let placeMapped = [];

  if (place && place.nodes && place.nodes.length > 0) {
    placeMapped = [
      {
        title: 'Place',
        id: 'Place',
        episodes: place.nodes.map((p) => {
          return {
            uri: p.uri,
            date: p.date,
            acfEpisode: {
              episodeTitle: p.title,
              thumbnailImage: p.acfPlace.placeImage,
            },
          };
        }),
        link: '/places/',
      },
    ];
  }

  return [
    {
      id: 'series',
      title: 'SERIES',
      data: seasonMapped,
      type: 'landscape',
    },
    // {
    //   id: 'timeframe',
    //   title: 'Timeframe',
    //   data: timeframe,
    //   type: 'portrait'
    // },
    {
      id: 'BEHIND THE SCENES',
      data: btsMapped,
      type: 'portrait',
    },
    {
      id: 'bible',
      data: bibleMapped,
      type: 'portrait',
    },
    {
      id: '360',
      data: eposides360Mapped,
      type: 'portrait',
    },
    {
      id: 'people',
      data: peopleMapped,
      type: 'portrait',
    },
    {
      id: 'place',
      data: placeMapped,
      type: 'portrait',
    },
    {
      id: 'adverts',
      data: advertsMapped,
      type: 'portrait',
    },
    {
      id: 'trailers',
      data: trailersMapped,
      type: 'portrait',
    },
  ];
};

// This function is deprecated . Was used for WordPress Structure Data, New functions in added in utils/watch.js
export const getEpisodeThumbnail = (episode, quality = 'mqdefault', useSourceUrl = false) => {
  let thumbnailUrl = '';

  if (episode.acfEpisode && episode.acfEpisode.thumbnailImage) {
    if (useSourceUrl) thumbnailUrl = episode.acfEpisode.thumbnailImage.sourceUrl;
    else
      thumbnailUrl =
        episode.acfEpisode.thumbnailImage?.localFile &&
        episode.acfEpisode.thumbnailImage?.localFile.childImageSharp &&
        episode.acfEpisode.thumbnailImage?.localFile.childImageSharp.fluid
          ? episode.acfEpisode.thumbnailImage?.localFile.childImageSharp.fluid.src
          : episode.acfEpisode.thumbnailImage.sourceUrl;
  } else if (episode.acfEpisode && episode.acfEpisode.youtubeId) {
    thumbnailUrl = episode.acfEpisode.youtubeId;
    if (thumbnailUrl.includes('youtube.com')) {
      thumbnailUrl = thumbnailUrl.split('v=')[1];
      const ampersandPosition = thumbnailUrl.indexOf('&');
      if (ampersandPosition !== -1) {
        thumbnailUrl = thumbnailUrl.substring(0, ampersandPosition);
      }
    }
    thumbnailUrl = `https://img.youtube.com/vi/${thumbnailUrl}/${quality}.jpg`;
  }

  return thumbnailUrl;
};

export const getEpisodeVideoUrl = (episode) => {
  if (episode && episode.acfEpisode && episode.acfEpisode.youtubeId) {
    if (episode.acfEpisode.youtubeId.includes('youtube.com'))
      return `https://www.youtube.com/embed/${episode.acfEpisode.youtubeId.split('=')[1]}`;
    else return `https://www.youtube.com/embed/${episode.acfEpisode.youtubeId}`;
  }
};

export const getVideoUrl = (embedUrl, languageCode = 'en') => {
  if (embedUrl.includes('youtube.com') || embedUrl.includes('youtu.be')) {
    let videoId = '';
    const url = new URL(embedUrl);

    if (url.hostname === 'youtu.be') {
      videoId = url.pathname.slice(1);
    } else {
      videoId = url.searchParams.get('v');
    }

    return `https://www.youtube.com/embed/${videoId}?hl=${languageCode}&cc_lang_pref=${languageCode}&cc=1`;
  }

  // Handle other video platforms if necessary
  return embedUrl;
};

export const getVideoThumbnail = (videoId, quality = 'mqdefault') => {
  if (!videoId) return '';
  let thumbnailUrl = videoId;
  if (thumbnailUrl.includes('youtube.com')) {
    thumbnailUrl = thumbnailUrl.split('v=')[1];
    const ampersandPosition = thumbnailUrl.indexOf('&');
    if (ampersandPosition !== -1) {
      thumbnailUrl = thumbnailUrl.substring(0, ampersandPosition);
    }
  }
  thumbnailUrl = `https://img.youtube.com/vi/${thumbnailUrl}/${quality}.jpg`;
  return thumbnailUrl;
};

export const getReadThumbnailFromRead = (read) => {
  if (read && (read.featuredImage || read.acfReadSection.featuredVideoId)) {
    if (read.featuredImage && read.featuredImage.node) {
      if (read.featuredImage.node?.localFile && read.featuredImage.node?.localFile.childImageSharp)
        return read.featuredImage.node?.localFile.childImageSharp.fluid.src;
      return read.featuredImage.node.sourceUrl;
    } else {
      return getVideoThumbnail(read.acfReadSection.featuredVideoId);
    }
  }
  return null;
};

export const serializeReadRepeater = (read) => {
  if (
    read &&
    read.acfReadSection &&
    read.acfReadSection.repeatContent &&
    read.acfReadSection.repeatContent.length > 0
  ) {
    let readBodyArray = [];
    let tempBody = [];
    let hasBackground = false,
      hasElementBackground = false,
      prevHasBackground = false;
    for (let index = 0; index < read.acfReadSection.repeatContent.length; index++) {
      const element = read.acfReadSection.repeatContent[index];
      hasElementBackground = element.sectionBackgroundImage && element.sectionBackgroundImage.sourceUrl ? true : false;

      if (hasBackground !== hasElementBackground) {
        if (tempBody.length > 0) {
          readBodyArray.push({
            overBg: !hasBackground && prevHasBackground,
            hasBackground,
            sections: tempBody,
          });
          prevHasBackground = hasBackground;
        }
        hasBackground = hasElementBackground;
        tempBody = [];
      }
      tempBody.push(element);
    }

    if (tempBody.length > 0)
      readBodyArray.push({
        overBg: !hasBackground && prevHasBackground,
        hasBackground,
        sections: tempBody,
      });

    if (readBodyArray.length > 0 && read.acfReadSection.featuredVideoId) {
      let lastSectionBgColor = 'Light';
      const lastBodySectionsCount = readBodyArray[readBodyArray.length - 1].sections.length;

      if (lastBodySectionsCount > 0) {
        lastSectionBgColor =
          readBodyArray[readBodyArray.length - 1].sections[lastBodySectionsCount - 1].backgroundColor;
      }

      readBodyArray[readBodyArray.length - 1].sections.push({
        type: 'Video',
        backgroundColor: lastSectionBgColor,
        video: read.acfReadSection.featuredVideoId,
        image:
          read.acfReadSection.postBanner && read.acfReadSection.postBanner.sourceUrl
            ? read.acfReadSection.postBanner.sourceUrl
            : null,
        isFeaturedVideo: true,
      });
    }
    return readBodyArray;
  }
  return [];
};

export const getNumericPriceAndDenomination = (price) => {
  var numPrice = price.split('');
  const denomination = numPrice.shift();
  numPrice = Number(numPrice.join(''));
  return {
    denomination,
    price: numPrice.toFixed(2),
  };
};

export const sortByDateAndSlice = (data, count, fieldName = 'date') => {
  if (!data) return [];
  return data
    .slice()
    .sort((a, b) => {
      if (fieldName === 'date') {
        return new Date(b.date) - new Date(a.date);
      } else {
        if (a[fieldName] === null) {
          return 1;
        } else if (b[fieldName] === null) {
          return -1;
        }
        return a[fieldName] - b[fieldName];
      }
    })
    .slice(0, count);
};

export const mapWatchMenuTabData = (data) => {
  const {
    season1,
    season2,
    season3,
    churches7,
    seasons,
    bible,
    episodes360,
    people,
    place,
    allWpSubPeriod,
    adverts,
    trailers,
  } = data;

  let seasonMapped = [];

  const season1Data = seasons.nodes.find((s) => s.title === `Season 1`);

  if (season1Data) {
    seasonMapped.push({
      label: `Season 1: ${season1Data.acfSeason?.seasonTitle}`,
      key: 'season1',
      items: season1.nodes.map((ep) => {
        return {
          key: ep.id,
          label: ep.acfEpisode.episodeTitle,
          url: ep.uri,
          order: ep.menuOrder,
          image: ep.acfEpisode.youtubeId,
          thumbnail: ep.acfEpisode.thumbnailImage,
        };
      }),
      viewAllLink: '/episode_category/season1/',
    });
  }

  const season2Data = seasons.nodes.find((s) => s.title === `Season 2`);

  if (season2Data) {
    seasonMapped.push({
      label: `Season 2: ${season2Data.acfSeason?.seasonTitle}`,
      key: 'season2',
      items: season2.nodes.map((ep) => {
        return {
          key: ep.id,
          label: ep.acfEpisode.episodeTitle,
          url: ep.uri,
          order: ep.menuOrder,
          image: ep.acfEpisode.youtubeId,
          thumbnail: ep.acfEpisode.thumbnailImage,
        };
      }),
      viewAllLink: '/episode_category/season2/',
    });
  }

  const season3Data = seasons.nodes.find((s) => s.title === `Season 3`);

  if (season3Data) {
    seasonMapped.push({
      label: `Season 3: ${season3Data.acfSeason?.seasonTitle}`,
      key: 'season3',
      items: season3.nodes.map((ep) => {
        return {
          key: ep.id,
          label: ep.acfEpisode.episodeTitle,
          url: ep.uri,
          order: ep.menuOrder,
          image: ep.acfEpisode.youtubeId,
          thumbnail: ep.acfEpisode.thumbnailImage,
        };
      }),
      viewAllLink: '/episode_category/season3/',
    });
  }

  // const churches7Data = seasons.nodes.find(s => s.title === `Season 3`)

  if (churches7) {
    seasonMapped.push({
      label: `The 7 Churches of Revelation`,
      key: 'churches7',
      items: churches7.nodes.map((ep) => {
        return {
          key: ep.id,
          label: ep?.acfEpisode?.episodeTitle,
          url: ep?.uri,
          order: ep?.menuOrder,
          image: ep?.acfEpisode?.youtubeId,
          thumbnail: ep?.acfEpisode?.thumbnailImage,
        };
      }),
      viewAllLink: '/episode_category/7-churches/',
    });
  }

  let timeframe = [];
  let bibleMapped = [];

  if (allWpSubPeriod) {
    timeframe = [
      {
        key: 'allSubPeriods',
        items: sortByDateAndSlice(allWpSubPeriod.nodes, allWpSubPeriod.nodes.length, 'menuOrder').map((ep) => {
          return {
            key: ep.id,
            label: ep.title,
            url: ep.uri,
            disable: !(ep.acfSubPeriod.episodes && ep.acfSubPeriod.episodes.length > 0),
          };
        }),
      },
    ];
  }

  if (bible && bible.timePeriods && bible.timePeriods.nodes.length > 0) {
    let subPeriods = [];

    for (let index = 0; index < bible.timePeriods.nodes.length; index++) {
      const timePeriod = bible.timePeriods.nodes[index];
      if (
        timePeriod &&
        timePeriod.acfTimePeriod &&
        timePeriod.acfTimePeriod.subPeriod &&
        timePeriod.acfTimePeriod.subPeriod.length > 0
      ) {
        const filtered = timePeriod.acfTimePeriod.subPeriod.filter(
          (sub) => !subPeriods.find((subPeriod) => subPeriod.id === sub.id)
        );
        subPeriods = [...subPeriods, ...filtered];
      }
    }

    bibleMapped = [
      {
        label: 'Bible',
        key: 'bible',
        items: sortByDateAndSlice(subPeriods, subPeriods.length, 'menuOrder').map((subPeriod) => {
          return {
            key: subPeriod.id,
            label: subPeriod.title,
            url: subPeriod.uri,
            disable: !(subPeriod.acfSubPeriod.episodes && subPeriod.acfSubPeriod.episodes.length > 0),
          };
        }),
      },
    ];
  }

  let people_placeMapped = [];

  if (people && people.nodes && people.nodes.length > 0) {
    people_placeMapped.push({
      label: 'People',
      key: 'people',
      items: people.nodes.map((person) => {
        return {
          key: person.id,
          label: person.title,
          url: person.uri,
        };
      }),
      viewAllLink: '/people/',
    });
  }

  if (place && place.nodes && place.nodes.length > 0) {
    people_placeMapped.push({
      label: 'Places',
      key: 'places',
      items: place.nodes.map((p) => {
        return {
          key: p.id,
          label: p.title,
          url: p.uri,
        };
      }),
      viewAllLink: '/places/',
    });
  }

  let eposides360Mapped = [];

  if (episodes360) {
    eposides360Mapped = [
      {
        key: '360',
        items: episodes360.nodes.map((ep) => {
          return {
            key: ep.id,
            label: ep.acfEpisode.episodeTitle,
            url: ep.uri,
          };
        }),
        viewAllLink: '/episode_category/360/',
      },
    ];
  }

  let advertsMapped = [];

  if (adverts) {
    advertsMapped = [
      {
        key: 'adverts',
        items: adverts.nodes.map((ep) => {
          return {
            key: ep.id,
            label: ep.acfEpisode.episodeTitle,
            url: ep.uri,
          };
        }),
        viewAllLink: '/episode_category/adverts/',
      },
    ];
  }

  let trailersMapped = [];

  if (trailers) {
    trailersMapped = [
      {
        key: 'trailers',
        items: trailers.nodes.map((ep) => {
          return {
            key: ep.id,
            label: ep.acfEpisode.episodeTitle,
            url: ep.uri,
            image: ep.acfEpisode.youtubeId,
            thumbnail: ep.acfEpisode.thumbnailImage,
          };
        }),
        viewAllLink: '/episode_category/trailers/',
      },
    ];
  }

  let btsMapped = [];

  if (seasons) {
    let btsSeasons = [];

    if (seasons && seasons.nodes && seasons.nodes.length > 0) {
      for (let index = 0; index < 3; index++) {
        const season = seasons.nodes.find((s) => s.title === `Season ${index + 1}`);
        if (season) {
          btsSeasons.push({
            key: season.id,
            label: season.title,
            url: `/bts/season${index + 1}/`,
            image: 'Season Featured Image',
            thumbnail: season.featuredImage?.node,
          });
        }
      }
    }

    btsMapped = [
      {
        key: 'bts',
        items: btsSeasons,
        column: 3,
        viewAllLink: '/episode_category/bts/',
      },
    ];
  }

  return [
    {
      key: 'series',
      label: 'Series',
      data: seasonMapped,
    },
    {
      key: 'timeframe',
      label: 'Timeframe',
      data: timeframe,
    },
    {
      key: 'people-and-places',
      label: 'People and Places',
      data: people_placeMapped,
    },
    {
      key: 'bible',
      label: 'Bible',
      data: bibleMapped,
    },
    {
      key: 'bts',
      label: 'Bts',
      data: btsMapped,
    },
    {
      key: '360',
      label: '360',
      data: eposides360Mapped,
    },
    {
      key: 'adverts',
      label: 'adverts',
      data: advertsMapped,
    },
    {
      key: 'trailers',
      label: 'trailers',
      data: trailersMapped,
    },
  ];
};

export const sortArrayWithName = (data, keyName = '', sortKeys = []) => {
  let newArray = [];

  for (let index = 0; index < sortKeys.length; index++) {
    const sortKey = sortKeys[index];
    const element = data.find((e) => e[keyName].toLowerCase() === sortKey.toLowerCase());

    if (element) newArray.push(element);
  }

  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    const key = sortKeys.find((key) => key.toLowerCase() === element[keyName].toLowerCase());
    if (!key) newArray.push(element);
  }

  return newArray;
};

export const mapReadMenuTabData = (readCategories) => {
  return sortArrayWithName(
    readCategories.nodes
      .filter((cat) => cat.readAll.nodes.length > 0)
      .map((cat) => {
        let subCategories = [];
        for (let index = 0; index < cat.readAll.nodes.length; index++) {
          const element = cat.readAll.nodes[index];
          const elementMapped = {
            key: element.id,
            url: element.link,
            label: element.title,
            menuOrder: element.menuOrder,
          };

          for (let index1 = 0; index1 < element.readCategories.nodes.length; index1++) {
            const category = element.readCategories.nodes[index1];

            if (
              !category.name.startsWith('Season') &&
              !category.name.startsWith('Blog') &&
              !category.name.startsWith('Featured')
            ) {
              const hasCat = subCategories.findIndex((tempCat) => tempCat.key === category.id);

              if (hasCat >= 0) {
                subCategories[hasCat].items.push(elementMapped);
              } else {
                subCategories.push({
                  key: category.id,
                  label: category.name,
                  items: [elementMapped],
                  viewAllLink: `/read/${cat.slug}/${category.slug}`,
                });
              }
            } else if (category.name.startsWith('Blog')) {
              if (subCategories.length === 1) {
                subCategories[0].items.push(elementMapped);
              } else {
                subCategories.push({
                  key: category.id,
                  label: category.name,
                  items: [elementMapped],
                  viewAllLink: `/read/${cat.slug}/`,
                });
              }
              break;
            }
          }
        }
        return {
          key: cat.id,
          label: cat.name,
          data: sortArrayWithName(subCategories, 'label', ['Episodes']).map((category) => {
            return {
              ...category,
              items: sortByDateAndSlice(category.items, 8, 'menuOrder'),
            };
          }),
        };
      }),
    'label',
    ['Season 1', 'season 2', 'season 3', 'Blog']
  );
};

export const mapReadPageData = (readCategories) => {
  return sortArrayWithName(
    readCategories.nodes
      .filter((cat) => cat.readAll.nodes.length > 0)
      .map((cat) => {
        let subCategories = [];
        for (let index = 0; index < cat.readAll.nodes.length; index++) {
          const element = cat.readAll.nodes[index];

          for (let index1 = 0; index1 < element.readCategories.nodes.length; index1++) {
            const category = element.readCategories.nodes[index1];

            if (
              !category.name.startsWith('Season') &&
              !category.name.startsWith('Blog') &&
              !category.name.startsWith('Featured')
            ) {
              const hasCat = subCategories.findIndex((tempCat) => tempCat.key === category.id);

              if (hasCat >= 0) {
                subCategories[hasCat].items.push(element);
              } else {
                subCategories.push({
                  key: category.id,
                  label: category.name,
                  items: [element],
                  viewAllLink: `/read/${cat.slug}/${category.slug}`,
                });
              }
            } else if (category.name.startsWith('Blog')) {
              if (subCategories.length === 1) {
                if (subCategories[0].items.length < 12) subCategories[0].items.push(element);
              } else {
                subCategories.push({
                  key: category.id,
                  label: category.name,
                  items: [element],
                  viewAllLink: `/read/${cat.slug}/`,
                });
              }
              break;
            }
          }
        }
        return {
          key: cat.id,
          label: cat.name,
          data: sortArrayWithName(subCategories, 'label', ['Episodes']).map((category) => {
            return {
              ...category,
              items: sortByDateAndSlice(category.items, 12, 'menuOrder'),
            };
          }),
        };
      }),
    'label',
    ['Season 1', 'season 2', 'season 3', 'Blog']
  );
};

export const getEpisodesFromCategory = (category) => {
  if (category && category.episodes && category.episodes.nodes.length > 0) {
    let episodes = category.episodes.nodes;
    if (category.name.startsWith('Season ')) {
      episodes = category.episodes.nodes.filter((episode) => {
        for (let index = 0; index < episode.episodeCategories.nodes.length; index++) {
          const element = episode.episodeCategories.nodes[index];
          if (element.name === 'BTS') return false;
        }
        return true;
      });
    }

    return sortByDateAndSlice(episodes, episodes.length, 'menuOrder');
  }

  if (category && category.acfPerson && category.acfPerson.episodes) return category.acfPerson.episodes;

  if (category && category.acfPlace && category.acfPlace.episodes) return category.acfPlace.episodes;

  if (category && category.edges)
    return sortByDateAndSlice(
      category.edges.map((ep) => ep.node),
      category.edges.length,
      'menuOrder'
    );

  if (category && category.acfSubPeriod && category.acfSubPeriod.episodes) return category.acfSubPeriod.episodes;

  return [];
};

export const getListDonationProjectForSelect = (donationProject, donationLanguage, options) => {
  console.log(donationProject, 'findOtherProject');
  const findDonationLanguageProject = donationProject?.find(
    (item) => item?.detailsPage?.document?.__typename === 'PrismicLanguageDonationsPage'
  );
  const findOtherProject = donationProject?.filter(
    (item) => item?.detailsPage?.document?.__typename !== 'PrismicLanguageDonationsPage'
  );

  const lists = findOtherProject.map((item) => {
    return {
      label: item?.title?.text,
      value: item?.detailsPage?.id ? item?.detailsPage?.id : item?.title?.text?.split(' ').join('-'),
      parentProjectName: item?.title?.text,
      parentProjectId: item?.detailsPage.id ? item?.detailsPage?.id : item?.title?.text?.split(' ').join('-'),
      isDefaultSelected: item.isDefaultSelected,
    };
  });
  const donationProjectLists = donationLanguage?.map((item) => {
    return {
      label: 'Lineage in ' + item?.primary?.languageName?.text,
      value: item.id,
      parentProjectName: findDonationLanguageProject?.title?.text,
      parentProjectId: findDonationLanguageProject?.detailsPage?.id
        ? findDonationLanguageProject?.detailsPage?.id
        : findDonationLanguageProject?.title?.text?.split(' ').join('-'),
      childProjectId: item.id,
      childProjectName: 'Lineage in ' + item?.primary?.languageName?.text,
      flagCode: item?.primary?.flagCode?.text,
      isDefaultSelected: item.isDefaultSelected,
    };
  });

  if (options?.onlyShowLanguageProject) {
    return donationProjectLists;
  }
  return [...(lists || []), ...(donationProjectLists || [])];
};
